<template>
  <div
    class="d-flex flex-row align-stretch mb-8 avatar-wrapper"
    :class="{
      'file-upload--dragging': isDragging,
      'col-6': isDragging,
    }"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
    @drop.prevent="onDrop"
  >
    <div>
      <v-skeleton-loader
        v-if="loading"
        width="160"
        height="160"
        type="card-avatar"
        tile
      ></v-skeleton-loader>
      <user-avatar v-else :avatar="tmpAvatar" tile size="160" />
    </div>

    <div
      class="d-flex flex-column justify-space-around ml-6"
      v-if="showUploadBtn"
    >
      <v-btn
        text
        class="text-capitalize font-weight-bold text-light-blue"
        @click="showCropperModal = !showCropperModal"
        :loading="loading"
        :disabled="disabled"
      >
        <img src="@/assets/icons/icon-image.svg" class="mr-2" alt />Upload Image
      </v-btn>
      <v-btn
        v-if="tmpAvatar"
        text
        class="text-capitalize font-weight-bold text-light-grey"
        @click="deleteConfirmDialog = true"
        :loading="loading"
        :disabled="disabled"
      >
        <img src="@/assets/icons/icon-trash.svg" class="mr-2" alt /> Delete
        Image
      </v-btn>
    </div>

    <image-cropper
      v-model="showCropperModal"
      @change="uploadAvatar"
      :maxWidth="maxWidth"
      :maxHeight="maxHeight"
      ref="uploadButton"
    />

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Remove Image"
      message="Are you sure you want to remove the image?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteAvatar"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mdiImagePlus, mdiDelete } from '@mdi/js'
import ImageCropper from '@/components/modals/ImageCropper'
import UserAvatar from '@/components/UserAvatar'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FileUploadMixin from '@/utils/mixins/FileUpload'

export default {
  components: {
    ImageCropper,
    UserAvatar,
    ConfirmModal,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin, FileUploadMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
    },

    maxHeight: {
      type: Number,
    },

    showUploadBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        addImage: mdiImagePlus,
        delete: mdiDelete,
      },
      loading: false,
      showCropperModal: false,
      deleteConfirmDialog: false,
      tmpAvatar: null,
      tmpFile: null,
      isDragging: false,
    }
  },

  computed: {},

  methods: {
    async uploadAvatar(image) {
      if (this.loading) return

      this.tmpAvatar = image.url
      this.tmpFile = image.file
    },

    async deleteAvatar() {
      this.deleteConfirmDialog = false
      this.tmpAvatar = null
      this.tmpFile = null
    },

    getAvatarUpload(form) {
      form.append('avatar', this.tmpFile)
      form.append('avatar_url', this.tmpAvatar)
    },

    onDrop(event) {
      event.preventDefault()
      const files = event.dataTransfer.files

      if (files.length > 0) {
        if (files.length > 1) {
          this.showSnackbar('You can upload a maximum of one image.', false)
        } else {
          const file = files[0]
          if (file.type.startsWith('image/')) {
            this.$refs.uploadButton.onFileChange({ target: { files } })
          } else {
            this.showSnackbar('Please drop a valid image file.', false)
          }
        }
      }

      this.dragInside = false
      this.isDragging = false
      clearTimeout(this.timeout)
    },

    handleDragEnter() {
      this.dragInside = true
      this.isDragging = true
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
    },
    handleDragLeave() {
      this.dragInside = false
      this.timeout = setTimeout(() => {
        if (!this.dragInside) {
          this.isDragging = false
        }
      }, 50)
    },
    handleDragOver() {
      this.dragInside = true
    },
  },

  beforeDestroy() {
    this.tmpAvatar = null
    this.tmpFile = null
  },
}
</script>
<style lang="scss" scoped>
.file-upload--dragging {
  border-color: #1976d2;
  background-color: rgba(25, 118, 210, 0.1);
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
</style>
